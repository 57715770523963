import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { EChartsOption, ECharts } from 'echarts';
import { ValueFramework } from '../../../../horizon-project/models/ValueFramework';
import { AnalogueCotDataFormat } from '../../../../horizon-project/models/AnalogueCotDataFormat';
import { chartCicleColorDecider } from '../../../../horizon-project/utility-functions/ReimbursedColorDecide';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { ActivatedRoute } from '@angular/router';
import { HorizonProjectService } from '../../../../horizon-project/horizon-project.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { ColorSetService } from '../../../../shared/services/colorSet.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
// import { element } from 'protractor';

@Component({
  selector: 'he-project-analogue-selection-cot-chart',
  templateUrl: './project-analogue-selection-cot-chart.component.html',
  styleUrls: ['./project-analogue-selection-cot-chart.component.scss'],
})
export class ProjectAnalogueSelectionCotChartComponent
  implements OnInit, OnChanges
{
  public valueFrameworkData: ValueFramework[] = [];
  public analogueCotData: AnalogueCotDataFormat[] = [];
  @Input() selectedCOTPriceTypeView = '';
  @Input() selectedPriceType = '';
  @Output() chartInstance = new EventEmitter<any>();
  private unsubscriber$ = new Subject<void>();
  selectedCurrency = '';
  selectedCurrencySymbol = '';

  constructor(
    private anlogueService: AnalogueService,
    private countryFlagService: CountryFlagService,
    private projectService: HorizonProjectService,
    private activatedRouter: ActivatedRoute,
    private currencyConversionService: CurrencyConversionService,
    private colorSetService: ColorSetService,
    private sharedService:SharedService
  ) {}

  public chartOption!: EChartsOption;
  public updateChartOption!: EChartsOption;
  chartSeriesData: any[] = [];

  // we can get the countries from the service
  countries: string[] = [];
  countriesInfoData: any[] = [];
  projectId = '';

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.projectId = p.project;
        if (this.projectId) {
          this.loadData(this.projectId);
          this.initChartData();
        }
      });

    this.currencyConversionService
      .GetSelectedCurrency()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (currency: string) => {
        this.selectedCurrency = currency;
        if (currency.toLowerCase().trim() == 'local') {
          this.selectedCurrency =
            this.currencyConversionService.GetDefaultCurrency();
          this.selectedCurrencySymbol =
            this.currencyConversionService.GetDefaultCurrencySymbol();
        } else {
          this.selectedCurrency = currency;
          this.selectedCurrencySymbol =
            this.currencyConversionService.GetSelectedCurrencySymbol();
        }
        const selectedProjectId =
          await this.projectService.GetSelectedProjectId();
        if ( this.projectId && this.projectId == selectedProjectId) {
          this.loadData(this.projectId);
        }
      });
  }

  /**
   * Initialises the chart's properties  
   */
  private initChartData() {
    this.chartOption = {
      grid: {
        left: '2%',
        right: '0%',
        bottom: '5%',
        containLabel: true,
      },
      title: {
        text: 'Analogue:',
        left: '0px',
        top: 0,
        textStyle: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: 20,
          color: '#374050',
        },
        padding: [3, 0],
      },
      legend: {
        show: true,
        left: 100,
        textStyle: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 20,
          verticalAlign: 'middle',
        },
      },
      tooltip: {
        formatter(params: any) {
          const data = params || [0, 0];
          return [
            data.seriesName,
            data.marker +
              data.name +
              ': &nbsp;&nbsp;' +
              data.data.currencySymbol +
              Math.round(parseFloat(data.value))
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          ].join('<br/>');
        },
      },
      xAxis: {
        type: 'category',
        data: this.countries.map((x) => this.capitaliseString(x)),
        axisLabel: {
          formatter(value: any) {
            return (
              '{' +
              value.replace(' ', '').toUpperCase() +
              '| }  {value|' +
              value +
              '}'
            );
          },
          fontSize: 11,
          margin: 20,
          rich: {
            value: {
              lineHeight: 35,
              align: 'left',
            },
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: `${this.selectedCurrencySymbol}  {value}`,
        },
        min: 0,
      },
      series: [],
    };
  }

  /**
   * loads the cot chart data from the service , 
   * applies the currency conversion on incoming data based on selected Currency Code.
   */
  private async loadData(projectId: string) {
    if (projectId) {
      if (this.sharedService.isBrandCotDataCall$.value) {
        // Use existing data if the flag is true
        let newCotData: AnalogueCotDataFormat[] = JSON.parse(
          JSON.stringify(this.sharedService.BrandCotDatadata$.value || [])
        );
        for (let index = 0; index < newCotData.length; index++) {
          const element = newCotData[index];
          element.launchPrice = await this.getCurrencyConvertedValue(element.launchPrice);
          element.currentPrice = await this.getCurrencyConvertedValue(element.currentPrice);
          element.reimbursedPrice = await this.getCurrencyConvertedValue(element.reimbursedPrice);
          element.netPrice = await this.getCurrencyConvertedValue(element.netPrice);
        }
        this.analogueCotData = newCotData;
      } else {
        // Make the API call if the flag is false
        await this.anlogueService
          .getAnalogueCotData(projectId)
          .then(async (cotData: AnalogueCotDataFormat[]) => {
            let newCotData: AnalogueCotDataFormat[] = JSON.parse(JSON.stringify(cotData));
            for (let index = 0; index < newCotData.length; index++) {
              const element = newCotData[index];
              element.launchPrice = await this.getCurrencyConvertedValue(element.launchPrice);
              element.currentPrice = await this.getCurrencyConvertedValue(element.currentPrice);
              element.reimbursedPrice = await this.getCurrencyConvertedValue(element.reimbursedPrice);
              element.netPrice = await this.getCurrencyConvertedValue(element.netPrice);
            }
            this.analogueCotData = newCotData;
            this.sharedService.BrandCotDatadata$.next(newCotData); // Update shared data
            this.sharedService.isBrandCotDataCall$.next(true); // Set the flag to true
          });
      }
    
      if (this.analogueCotData.length > 0) {
        this.analogueCotData.sort((a: any, b: any) => a.sequenceNo - b.sequenceNo);
        this.chartSeriesData = [];
        this.formatChartData(this.selectedPriceType);
      }
    }
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartSeriesData = [];
    this.formatChartData(changes.selectedPriceType.currentValue);
  }

  /**
   * formats the data in format which can be injected to the chart service.
   * @param priceType refers to the price type that is selected in the parent grid.
   */
  formatChartData(priceType?: string) {
    this.initChartData();
    this.updateChartOption = {};
    this.chartSeriesData = [];
    this.countries = [];
    this.countriesInfoData = [];
    const selectedPrice = priceType ? priceType : 'launchPrice';
    const brands: any[] = [];
    this.analogueCotData.map((row: AnalogueCotDataFormat) => {
      const brandObj = {
        brandName: row.brandName,
        tli: row.tli,
        inn: row.inn,
        moa:row.moa,
        sequenceNo:row.sequenceNo
      };
      brands.filter(
        (brand: any) =>
          brand.brandName === row.brandName &&
          brand.tli === row.tli &&
          brand.inn === row.inn &&
          brand.moa === row.moa
      ).length === 0
        ? brands.push(brandObj)
        : null;
      if (!this.countries.includes(row.country.toUpperCase())) {
        this.countries.push(row.country.toUpperCase());
        this.countriesInfoData.push({
          country: row.country,
          flagId:this.countryFlagService.getFlagId( row.country),
          infoIconData: row.infoIconData.filter((x:any)=> x.priceType === this.selectedCOTPriceTypeView)[0].infoIconData,
        });
      }
    });

    this.countries.sort()
    this.countriesInfoData.sort((a,b)=>a.country.localeCompare(b.country))

    const countrysdata: any[] = [];
    // filter each countries data and push it to countrysdata[]
    this.countries.forEach((country) => {
      let countrydata: any[] = [];
      countrydata = this.analogueCotData.filter((row: any) => {
        return row.country.toLowerCase() === country.toLowerCase();
      });
      if (countrydata.length > 0) {
        countrysdata.push(countrydata);
      }
    });
    countrysdata.forEach((countryData) => {
      brands.forEach((brand) => {
        const bdata = countryData.filter((row: any) => {
          return row.brandName.toLowerCase().trim() === brand.brandName.toLowerCase().trim() &&
          row.tli.toLowerCase().trim() === brand.tli.toLowerCase().trim()&&
          row.inn.toLowerCase().trim() === brand.inn.toLowerCase().trim() &&
          row.moa.toLowerCase().trim() === brand.moa.toLowerCase().trim();
        });
        if(bdata[0] !== undefined){
        const temp = {
          value: bdata[0] ? bdata[0][selectedPrice] : '',
          tli:bdata[0].tli,
          inn:bdata[0].inn,
          moa:bdata[0].moa,
          currencySymbol: this.selectedCurrencySymbol,
          label: {
            show: true,
            formatter: '   ',
            borderColor: 'black',
            backgroundColor: chartCicleColorDecider(
              bdata[0] ? bdata[0]['reimbursedClassification'] : null
            ),
            borderRadius: 20,
            position: 'bottom',
          },
        };
        this.AddInSeriesData(temp, brand);
      }
      else{
        // Reason of creating gaps in graph for NA values
        const temp = {
          value: '0.00',
          tli:'',
          inn:'',
          moa:'',
          currencySymbol: this.selectedCurrencySymbol,
          label: {
            show: true,
            formatter: '   ',
            borderColor: 'black',
            backgroundColor: chartCicleColorDecider(null),
            borderRadius: 20,
            position: 'bottom',
          },
        };
        this.AddInSeriesData(temp, brand);
      }
      });
    });
    //const xAxisLabel = await this.xAxisLablePrepare();
    const tempBrands = brands.map(
      (x) => x.brandName.toUpperCase()[0] + x.brandName.toLowerCase().slice(1)
    );
    this.updateChartOption = {
      series: this.chartSeriesData,
      legend: { data: tempBrands },
      xAxis: {
        axisLabel: {
          show: false,
        },
        data: this.countries.map((x) => this.capitaliseString(x)),
      },
    };
    this.chartOption.legend={
        show: true,
        left: 100,
        type:tempBrands.length >20? "scroll":'plain',
        textStyle: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 20,
          verticalAlign: 'middle',
        },
      };
  }
  /**
   * helper function for formatting chart data
   * @param data analogue's formatted price data to be injected in chart.
   * @param brand analogue information to identify if that brand is already there in array.
   * @returns 
   */
  AddInSeriesData(data: any, brand: any) {
    let found = false;
    this.chartSeriesData.map((element) => {
      if (element.name.toLowerCase().trim() === brand.brandName.toLowerCase().trim() &&
      element.tli.toLowerCase().trim() === brand.tli.toLowerCase().trim()&&
          element.inn.toLowerCase().trim() === brand.inn.toLowerCase().trim() &&
          element.moa.toLowerCase().trim() === brand.moa.toLowerCase().trim()
      ) {
        element.data.push(data);
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    // did not found then add the element
    this.chartSeriesData.push({
      name: brand.brandName.charAt(0) + brand.brandName.slice(1).toLowerCase(),
      tli:brand.tli,
      inn:brand.inn,
      moa:brand.moa,
      color:this.colorSetService.setColor(brand.brandName),
      data: [data],
      type: 'bar',
      barGap: '10%',
      barCategoryGap: '10%',
      barMaxWidth: '10%',
    });
  }

  /**
   * prepares the label data for chart.
   * @returns 
   */
  async xAxisLablePrepare(): Promise<any> {
    const axisLabel: any = {
      formatter(value: any) {
        return (
          '{' + value.replace(' ', '').toUpperCase().trim() + '|}  ' + value
        );
      },
      margin: 20,
      rich: {
        value: {
          lineHeight: 35,
          align: 'center',
          color: 'black',
        },
      },
    };

    this.countries.forEach((element: string, index: number) => {
      axisLabel.rich[element.replace(' ', '').toUpperCase().trim()] = {
        height: 25,
        align: 'center',
        backgroundColor: {
          image:
            'assets/flags/' +
            this.countryFlagService.getFlagId(element) +
            '.svg',
        },
      };
    });
    return axisLabel;
  }

  capitaliseString = (data: string) => {
    let res = '';
    data.split(' ').forEach((element) => {
      res +=
        element.charAt(0).toUpperCase() + element.slice(1).toLowerCase() + ' ';
    });
    return res;
  };
  saveChartApi(ev: ECharts): void {
    this.chartInstance.emit(ev);
  }

  /**
   * converts the currency based on currency code selected.
   * @param data the number value which you are trying to convert.
   * @returns 
   */
  async getCurrencyConvertedValue(data: number) {
    const numericValue = data;
    const defaultCurrency = this.currencyConversionService.GetDefaultCurrency();
    let convertedData = 0.0;
    if (this.selectedCurrency.toLowerCase().trim() === 'local') {
      convertedData = numericValue;
    } else {
      convertedData = await this.currencyConversionService.convertCurrency(
        numericValue,
        defaultCurrency,
        this.selectedCurrency
      );
    }
    return convertedData;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
